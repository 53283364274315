import MonetizeIcon from 'images/landing-page/icons/monetize-4.svg'
import SendIcon from 'images/landing-page/icons/send-icon.svg'

export const TAB_NUMBER = {
  ONE: 'one',
  TWO: 'two',
  THREE: 'three',
  FOUR: 'four',
  FIVE: 'five',
}

export const SECTION_IDS = {
  USE_CASES: {
    id: 'use-cases',
    label: 'Use cases',
  },
  PRICING: {
    id: 'pricing',
    label: 'Pricing',
  },
  PRODUCTS: {
    MONETIZE: {
      id: 'monetize',
      label: 'Monetize',
      Icon: MonetizeIcon,
    },
    ENGAGE: {
      id: 'engage',
      label: 'Engage',
      Icon: SendIcon,
    },
  },
}

export const GA_EVENTS = {
  BOOK_DEMO_STARTED: 'demo_started',
  BOOK_DEMO_COMPLETED: 'demo_completed',
  SIGN_UP_STARTED: 'subscription_sign_up_started',
  SIGN_UP_COMPLETED: 'subscription_sign_up_completed',
  CONVERSION: 'conversion',
}

export const GA_EVENT_SOURCES = {
  NAV: 'navigation_cta',
  HEADER: 'header_cta',
  PRICING: 'pricing_cta',
}
